import { useContext, useState } from "react";
import TodoContext from "../Context/TodoContext";
import Swal from "sweetalert2";
const CreateTodo = () => {
  const { addtodo } = useContext(TodoContext);
  const [todo, setTodo] = useState(" ");
  const [loading, setloading] = useState(false);
  const createTodo = async (e) => {
    e.preventDefault();
    if (todo) {
      setloading(true);
      await addtodo(todo);
      setloading(false);
      document.getElementById("newTodo").value = "";
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };

  return (
    <>
      <h3>Create Todo :</h3>
      {loading && <div className="spinner-border text-info m-5"></div>}
      <form className="row my-3" onSubmit={(e) => createTodo(e)}>
        <div className="col-md-6">
          <input
            type="text"
            id="newTodo"
            className="form-control"
            placeholder="Write Todo"
            onChange={(e) => setTodo(e.target.value)}
          />
          <div className="form-text text-danger">
            {todo ? "" : "The Todo Can't Empty!"}
          </div>
        </div>
        <div className="col-auto">
          <button
            type="submit"
            className="btn btn-dark"
            disabled={todo ? false : true}
          >
            Create
          </button>
        </div>
      </form>
    </>
  );
};

export default CreateTodo;
