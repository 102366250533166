import { useContext, useEffect, useState } from "react";
import TodoContext from "../Context/TodoContext";
import FilterTodo from "../Components/FilterTodo";
import CreateTodo from "../Components/CreateTodo";
import UpdateTodo from "../Components/UpdateTodo";
import DeleteTodo from "../Components/DeleteTodo";

const Todos = () => {
  const { todos, error, fetchtodo } = useContext(TodoContext);
  const [loading, setloading] = useState(true);
  useEffect(() => {
    const getTodo = async () => {
      await fetchtodo();
      setloading(false);
    };
    getTodo();
  }, [fetchtodo]);

  return (
    <div className="container my-5">
      <div className="row gy-3 align-items-center">
        <div className="col-md-12">
          <CreateTodo />
          <hr />
          {loading && <div className="spinner-border text-info"></div>}
          {error && <h1 className="text-danger">{error}</h1>}
          <FilterTodo />
        </div>
        {todos &&
          todos.map((todo) => (
            <div key={todo.id} className="col-md-3">
              <div
                className={
                  todo.completed
                    ? "card  text-center bg-dark-subtle"
                    : "card  text-center bg-light"
                }
              >
                <div className="card-body">
                  <p className="card-text">
                    <span className="text-primary fw-bold">{todo.id}: </span>
                    {todo.completed ? (
                      <del>{todo.title}</del>
                    ) : (
                      <span>{todo.title}</span>
                    )}
                  </p>
                </div>
                <div className="card-footer">
                  <UpdateTodo todo={todo} />
                  <DeleteTodo todoid={todo.id} />
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Todos;
