import { useContext, useState } from "react";
import TodoContext from "../Context/TodoContext";

const FilterTodo = () => {
  const { filtertodo, fetchtodo } = useContext(TodoContext);
  const [loading, setloading] = useState(false);
  const filter = async (e) => {
    setloading(true);
    if (e.target.value === "All") {
      await fetchtodo();
    } else {
      await filtertodo(e.target.value);
    }
    setloading(false);
  };
  return (
    <div className="col-md-2">
      <div className="d-flex align-items-center">
        <h5>Filter</h5>
        <select
          onChange={(e) => filter(e)}
          className="form-select ms-2 form-select-sm"
        >
          <option>All</option>
          <option>5</option>
          <option>10</option>
          <option>20</option>
          <option>50</option>
          <option>100</option>
        </select>
      </div>
      {loading && <div className="spinner-border text-info m-5"></div>}
    </div>
  );
};

export default FilterTodo;
