import { useContext, useState } from "react";
import TodoContext from "../Context/TodoContext";

const DeleteTodo = ({todoid})=>{
    const [loading, setloading] = useState(false);
    const { deleteTodo } = useContext(TodoContext);

const deletetodo = async()=>{
    setloading(true);
    await deleteTodo(todoid);
}
    return(
        <>
                <i onClick={()=> deletetodo()} className="text-danger bi bi-x fs-3 ms-2"></i>
                {loading && <div className="spinner-border text-info"></div>}
        </>

    )
}
export default DeleteTodo;