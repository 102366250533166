import { useCallback, useReducer } from "react";
import TodoContext from "./TodoContext";
import TodoReducer from "./TodoReducer";
import axios from "axios";
import Swal from "sweetalert2";

const TodoProvider = ({ children }) => {
  const initializer = {
    todos: [],
    error: null,
  };

  const [state, dispatch] = useReducer(TodoReducer, initializer);

  const fetchtodo = useCallback(() => {
    axios
      .get("https://jsonplaceholder.typicode.com/todos/")
      .then((resposne) => {
        dispatch({ type: "load_todos", payload: resposne.data });
        dispatch({ type: "Error", payload: null });
      })
      .catch((err) => {
        dispatch({ type: "Error", payload: err.message });
        dispatch({ type: "load_todos", payload: [] });
      });
  }, []);

  const filtertodo = useCallback((countFilter) => {
    axios
      .get(`https://jsonplaceholder.typicode.com/todos/?_limit=${countFilter}`)
      .then((resposne) => {
        dispatch({ type: "filter_todo", payload: resposne.data });
        dispatch({ type: "Error", payload: null });
      })
      .catch((err) => {
        dispatch({ type: "Error", payload: err.message });
        dispatch({ type: "filter_todo", payload: [] });
      });
  }, []);

  const addtodo = useCallback((title) => {
    axios
      .post(`https://jsonplaceholder.typicode.com/todos/`, {
        title: title,
        completed: false,
      })
      .then((resposne) => {
        dispatch({ type: "add_todo", payload: resposne.data });
        dispatch({ type: "Error", payload: null });
        Swal.fire({
          icon: "success",
          text: "Todo Created Successfully",
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 2000,
          toast: true,
        });
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.message,
        });
      });
  }, []);


  const updateTodo = async(todo) => {
   await axios
      .put(`https://jsonplaceholder.typicode.com/todos/${todo.id}`, {
        title: todo.title,
        completed: !todo.completed,
      })
      .then((resposne) => {
        dispatch({ type: "update_todo", payload: resposne.data });
        dispatch({ type: "Error", payload: null });
        Swal.fire({
          icon: "success",
          text: "Todo Updated",
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 2000,
          toast: true,
        });
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.message,
        });
      });
  }
  const deleteTodo = useCallback((todoid) => {
    axios
      .delete(`https://jsonplaceholder.typicode.com/todos/${todoid}`)
      .then((resposne) => {
        dispatch({ type: "delete_todo", payload: todoid });
        dispatch({ type: "Error", payload: null });
        Swal.fire({
          icon: "warning",
          text: "Todo Remove",
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 2000,
          toast: true,
        });
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.message,
        });
      });
  }, []);
  return (
    <TodoContext.Provider value={{ ...state, fetchtodo, filtertodo, addtodo ,updateTodo,deleteTodo }}>
      {children}
    </TodoContext.Provider>
  );
};

export default TodoProvider;
