import { Link, NavLink } from "react-router-dom";
import  logo from "../logo.png";
const Header = ()=>{
return(
    <>
    <nav className="navbar navbar-expand-lg bg-body-tertiary">
  <div className="container-fluid">
    <Link className="navbar-brand" to="/"><img className="logo" alt="logo" src={logo}/></Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar" >
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbar">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <NavLink className={(e)=> e.isActive ? "nav-link active": "nav-link"} to="/">Home</NavLink>
        </li>
        <li className="nav-item">
          <NavLink className={(e)=> e.isActive ? "nav-link active": "nav-link"} to="/todos">Todos</NavLink>
        </li>
        </ul>
    </div>
  </div>
</nav>
    </>
)
}

export default Header;