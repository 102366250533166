const TodoReducer = (state, action) => {
  switch (action.type) {
    case "load_todos": {
      return {
        ...state,
        todos: action.payload,
      };
    }
    case "add_todo": {
      return {
        ...state,
        todos: [action.payload,...state.todos],
      };
    }
    case "update_todo": {
      return {
        ...state,
        todos: state.todos.map(todo => todo.id === action.payload.id ? {...todo, completed : action.payload.completed} : todo)
      };
    }
    case "delete_todo": {
      return {
        ...state,
        todos: state.todos.filter(todo => todo.id !== action.payload)
      };
    }
    case "filter_todo": {
      return {
        ...state,
        todos: action.payload,
      };
    }
    case "Error": {
      return {
        ...state,
        error: action.payload,
      };
    }
    default:
      return state;
  }
};

export default TodoReducer;
