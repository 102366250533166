const Home = () => {
  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-md-12 text-center">
          <h1 className="my-2">Lorem, ipsum dolor</h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ut
            incidunt excepturi esse ad! Velit suscipit hic ex dolorum tempora.
            Eos ex ipsam non? Ipsum quis commodi aspernatur sunt explicabo sequi
            minus minima? Consequatur nisi rem iste reprehenderit, nihil
            voluptates veniam accusantium labore tempora, iusto accusamus sunt,
            exercitationem incidunt repellat unde earum quis. Quidem dolores
            mollitia quasi deleniti temporibus architecto harum natus sint
            repudiandae asperiores, reprehenderit dicta fuga sapiente amet, iste
            possimus? Ea magnam dicta non cumque nostrum. Cum consequatur error
            animi repudiandae culpa nihil aspernatur dolorem neque ex! Error
            ipsum eius laudantium id cumque aliquam expedita ullam ex
            repudiandae minima!
          </p>
        </div>
      </div>
    </div>
  );
};

export default Home;
