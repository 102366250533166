import { useContext, useState } from "react";
import TodoContext from "../Context/TodoContext";

const UpdateTodo = ({ todo }) => {
  const [loading, setloading] = useState(false);
  const { updateTodo } = useContext(TodoContext);
  const update = async () => {
    setloading(true);
    await updateTodo(todo);
    setloading(false);
  };
  return (
    <>
      {todo.completed ? (
        <i
          onClick={() => update()}
          className=" text-success bi bi-check-all fs-3"
        >
          {loading && <div className="spinner-border text-info"></div>}
        </i>
      ) : (
        <i onClick={() => update()} className="bi bi-check fs-3"></i>
      )}
    </>
  );
};

export default UpdateTodo;
